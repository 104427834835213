<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1"
        >If you have any questions,
        <a class="text-primary text-decoration-underline" @click="toContact">
          please click here to submit.</a
        ></strong
      >
    </div>

    <div class="container mt-5 mb-5">
      <div class="qalist" v-loading="isLoading">
        <dl class="news_dl bg1 px-3" v-for="(item, index) in list" :key="index">
          <dt class="mt-5 mb-3">
            <h1>
              <span class="me-3">{{ item.date }}</span
              >{{ item.title }}
            </h1>
          </dt>
          <dd class="mb-5">
            <div class="answer" v-html="item.content"></div>
          </dd>
        </dl>
      </div>

      <!--<p v-if="loading" class="text-center fs-5 mt-3 mb-3">
        <i class="el-icon-loading"></i>
      </p>
      <p v-if="noMore" class="text-center fs-5 mt-3 mb-3">
        <i class="el-icon-minus"></i>
      </p>-->
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";

import Foot from "@/components/website/Foot.vue";
import { faqlist } from "@/api/index";

export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {
      list: [], // 存放数据列表
      //loading: false, // 加载状态
      //noMore: false,
      rows: 0, // 总数据条数
      perPage: 8, // 每页显示条数
      currentPage: 1, // 当前页码
    };
  },

  mounted() {
    this.getList(); // 页面加载时获取初始数据
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll); // 移除滚动事件监听器
  },

  methods: {
    getList() {
      //this.loading = true; // 开始加载数据
      const params = {
        page: this.currentPage,
        size: this.perPage,
      };
      // 调用 API 获取数据
      faqlist(params)
        .then((res) => {
          // 将新加载的数据添加到列表末尾
          this.list = [...this.list, ...res.data.data];
          this.rows = res.data.total;
        })
        .finally(() => {
          //this.loading = false; // 加载完成或失败后停止加载状态
          //this.noMore = true;
        });
    },

    handleScroll() {
      const bottomOffset = 100; // 距离底部的偏移量
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollHeight - scrollTop - clientHeight <= bottomOffset) {
        // 滚动到页面底部，加载更多数据
        if (!this.loading && this.currentPage * this.perPage < this.rows) {
          this.currentPage++;
          this.getList(); // 加载更多数据
        }
      }
    },

    changpage(page) {
      this.currentPage = page;
      this.getList(); // 加载指定页的数据
    },

    toContact() {
      this.$router.push("/contact");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
</style>
